var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-category-select" },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          },
          slot: "left-one"
        }),
        _c("i", {
          staticClass: "me-icon-check",
          attrs: { slot: "right-one" },
          on: {
            click: function($event) {
              return _vm.handlerSelectCallback()
            }
          },
          slot: "right-one"
        })
      ]),
      _c(
        "ul",
        { staticClass: "me-category-select__list" },
        _vm._l(_vm.categories, function(item, index) {
          return _c(
            "li",
            {
              key: index,
              class: { disable: _vm.selected.length >= 3 && !item.selected },
              on: {
                click: function($event) {
                  return _vm.handlerCheckSelected(item, index)
                }
              }
            },
            [
              _c("span", { domProps: { innerHTML: _vm._s(item.text) } }),
              item.selected
                ? _c("i", { staticClass: "me-icon-select" })
                : _vm._e()
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }