<template>
  <div class="me-category-select">
    <Header>
      <i class="me-icon-back" slot="left-one" @click="$router.go(-1)"></i>
      <i class="me-icon-check" slot="right-one" @click="handlerSelectCallback()"></i>
    </Header>
    <ul class="me-category-select__list">
      <li
        v-for="(item, index) in categories"
        :key="index"
        @click="handlerCheckSelected(item,index)"
        :class="{'disable':selected.length >=3 && !item.selected}"
      >
        <span v-html="item.text"></span>
        <i class="me-icon-select" v-if="item.selected"></i>
      </li>
    </ul>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import { getCategories } from "@/api/webApi";
export default {
  name: "category-select",
  components: {
    Header
  },
  data() {
    return {
      categories: [],
      selected: []
    };
  },
  watch: {
    "$store.state.selectedCategories": {
      handler(newly) {
        console.log(newly, "/newly");

        localStorage.setItem(
          "publish-categorys",
          newly.map(item => item.text).join(",") || ""
        );
      },
      deep: true
    }
  },
  created() {
    this.handlerGetCategories();
  },
  methods: {
    handlerSelectCallback() {
      this.$store.dispatch("commitSelectedCategories", this.selected);
      localStorage.setItem(
        "publish-categorys",
        this.selected.map(item => item.text).join(",")
      );
      this.$router.go(-1);
    },
    handlerCheckSelected(item, index) {
      localStorage.setItem('changeSelecte',true)
      if (this.selected.length < 3 || this.categories[index].selected) {
        this.categories[index].selected = !this.categories[index].selected;
        if (this.categories[index].selected) {
          this.selected.push(item);
        } else {
          this.selected.some((el, key) => {
            if (el.value === item.value) this.selected.splice(key, 1);
          });
        }
      }
    },
    async handlerGetCategories() {
      let params = {
        language: this.language
      };
      const result = await getCategories(params);
      if (result) {
        this.selected = this.$store.state.selectedCategories;
        this.categories = result.data.data.map(item => {
          let selected = false;
          this.selected.some(el => {
            if (el.value === item.category_id) {
              selected = true;
            }
          });
          return {
            text: item.category_name,
            value: item.category_id,
            selected: selected
          };
        });
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(me-category-select) {
  background: #ffffff;

  +e(list) {
    margin-top: 54px;

    li {
      line-height: 30px;
      text-align: left;
      display: flex;
      flex-flow: row;
      font-size: 14px;
      justify-content: space-between;
      padding: 0.5rem 0.9rem;
      border-bottom: 1px solid #f5f5f5;

      &.disable {
        color: #dddddd;
      }

      .me-icon-select {
        color: #33cc66;
        font-size: 24px;
      }
    }
  }
}
</style>